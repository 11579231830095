import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import LocalGasStationIcon from '@mui/icons-material/LocalGasStation';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import SpeedIcon from '@mui/icons-material/Speed';
import BuildIcon from '@mui/icons-material/Build';
import '../styles/CarDetials.css'

function CarDetials(){
    return (<>
    <div className="Detial">
        <div className="icons_info">
            <div className='icontext'>
                <CalendarTodayIcon style={{fontSize: 40, color: '#b6862d'}}></CalendarTodayIcon>
                <span>2025</span>
            </div>
            <div className='icontext'>
                <LocalGasStationIcon style={{fontSize: 40, color: '#b6862d'}}></LocalGasStationIcon>
                <span>Petrol</span>
            </div>
            <div className='icontext'>
                <DirectionsCarIcon style={{fontSize: 40, color: '#b6862d'}}></DirectionsCarIcon>
                <span>Automatic</span>
            </div>
            <div className='icontext'>
                <SpeedIcon style={{fontSize: 40, color: '#b6862d'}}></SpeedIcon>
                <span>248 HP</span>
            </div>
            <div className='icontext'>
                <BuildIcon style={{fontSize: 40, color: '#b6862d'}}></BuildIcon>
                <span>SUV</span>
            </div>
            
        </div>
        <hr className='Line'/>
        <div className="text_info">
            <div className='text_info_container'>
                <h2 className='left_title'>Registration</h2>
                <h2 className='right_title'>2025</h2>
            </div>
            <div className='text_info_container'>
                <h2 className='left_title'>Fuel Type</h2>
                <h2 className='right_title'>Petrol</h2>
            </div>
            <div className='text_info_container'>
                <h2 className='left_title'>Transmission</h2>
                <h2 className='right_title'>Automatic</h2>
            </div>
            <div className='text_info_container'>
                <h2 className='left_title'>Horsepower</h2>
                <h2 className='right_title'> 248/5250 HP</h2>
            </div>
            <div className='text_info_container'>
                <h2 className='left_title'>Body Type</h2>
                <h2 className='right_title'>SUV</h2>
            </div>
            <div className='text_info_container'>
                <h2 className='left_title'>Exterior Colour</h2>
                <h2 className='right_title'>Black | Gray | White | Green | Silver </h2>
            </div>
            <div className='text_info_container'>
                <h2 className='left_title'>Interior Colour</h2>
                <h2 className='right_title'>Light | Dark | Dark+Green</h2>
            </div>
        </div>
    </div>

    </>)
}

export default CarDetials;
