import Fimage from '../assets/images/5 yearsn.png'
import Timage from '../assets/images/2 yearsn.png'
import '../styles/TwoImages.css'
function TwoImages(){
    return ( <div className="two-images-container">
        <div className="image1">
            <img className="left_image" src={Fimage} alt="" />
        </div>
        <div className="image2">
            <img className='right_image' src={Timage} alt="" />
        </div>
    </div> )
}
export default TwoImages;