import '../styles/HeroSection.css'
import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom'; // Import useNavigate
// import Logo from '../assets/Falcons Lux Logo.png'
function HeroSection() {
    const navigate = useNavigate();
    const initialFormState = {
        name: '',
        email: '',
        phone: '',
        extension: '+971',
    };
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
        extension: '+971',
    });
    const [submissionStatus, setSubmissionStatus] = useState({
        open: false,
        message: '',
        success: false,
    });
    const [isSubmitted, setIsSubmitted] = useState(false); // Track submission status
    const [formError, setFormError] = useState(''); // Track form errors

    // Handle form input changes
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({ ...prevData, [name]: value }));
    };

    // Function to handle form submission
    const handleSubmit = async (e) => {
        e.preventDefault();
        if (isSubmitted) return; // Prevent resubmission if already submitted
        // Validate mandatory fields
        if (!formData.name || !formData.email || !formData.phone) {
            setFormError('Please fill in all mandatory fields.');
            return;
        }

        // Refresh access token and make Zoho API call
        try {
            await sendLeadDataToZoho();
            // await sendWebhook();
            setIsSubmitted(true); // Set submitted state to true
            setFormData(initialFormState); // Clear the form
            setFormError(''); // Clear error message on successful submission
        } catch (error) {
            console.error('Error:', error);
        }
    };

    const sendLeadDataToZoho = async () => {
        // const fullPhone = `${formData.extension} ${formData.phone}`;
        const leadData = {
            company: 'Your Company',
            first_name: formData.name,
            last_name: 'Unknown',
            email: formData.email,
            phone: formData.phone,
            brand: 'Your Brand',
            website: window.location.href,
            extension: formData.extension,
            Website: "https://falcons-luxury.com/page/gs8"
        };

        try {
            const response = await fetch('https://falconsgt.com/zoho_api.php', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': '*/*',
                },
                body: JSON.stringify(leadData),
            });

            const data = await response.json();
            console.log("Full Response:", data);

            if (!response.ok) {
                console.error("Server Error Details:", data);
                throw new Error(`Error ${response.status}: ${data.message || 'Unknown error'}`);
            }

            if (data.data) {
                setSubmissionStatus({
                    open: true,
                    message: 'Form submitted successfully!',
                    success: true,
                });
            } else {
                console.error("Error Details:", data);
                setSubmissionStatus({
                    open: true,
                    message: 'Error submitting form.',
                    success: false,
                });
            }
        } catch (error) {
            console.error("Request failed:", error);
            setSubmissionStatus({
                open: true,
                message: 'Network error submitting form.',
                success: false,
            });
        }
    };
    const [visibleLines, setVisibleLines] = useState(0); // Track visible lines
    const lines = [
        "GAC-GS8 2025",
        "UNLEASH THE THRILL OF LUXURY!",
        "Bank Finance Available",
        "2 Years Service Contract",
        "5 Years Gargash Warranty",
        "Multiple Colors Available",
        "Before: 169,000 AED",
        "After: 140,000 AED",
    ];

    useEffect(() => {
        const interval = setInterval(() => {
            setVisibleLines((prev) => {
                if (prev < lines.length) return prev + 1;
                clearInterval(interval); // Stop when all lines are visible
                return prev;
            });
        }, 1000); // Show a new line every second
        return () => clearInterval(interval); // Cleanup on unmount
    }, [lines.length]);
    return (
        <>
            <div className="hero">
                <div className="hero-content">
                    {/* Left Section: Text Container */}
                    <div className="left-section">
                        <div className="text_container">
                            <h1 className={`title1 ${visibleLines > 0 ? "fade-in" : ""}`}>
                                {lines[0]}
                            </h1>
                            <h1 className={`title ${visibleLines > 0 ? "fade-in" : ""}`}>
                                {lines[1]}
                            </h1>
                            <div className="sub-title">
                                {lines.slice(2).map((line, index) => (
                                    <p
                                        key={index}
                                        className={`sub-title-line ${visibleLines > index + 1 ? "fade-in" : ""}`}
                                        style={
                                            line.includes("Before") || line.includes("After")
                                                ? { fontWeight: "bold" }
                                                : {}
                                        }
                                    >
                                        {line.includes("Before") ? (
                                            <>
                                                <span className="price-label">Before: </span>
                                                <span className="price-before">
                                                    <s>{line.split(": ")[1]}</s>
                                                </span>
                                            </>
                                        ) : line.includes("After") ? (
                                            <>
                                                <span className="price-label">After: </span>
                                                <span className="price-after">{line.split(": ")[1]}</span>
                                            </>
                                        ) : (
                                            line
                                        )}
                                    </p>
                                ))}
                            </div>
                        </div>
                    </div>

                    {/* Right Section: Form */}
                    <div className="right-section">
                        <div className="banner-form-loading">
                            <form onSubmit={handleSubmit}>
                                <input
                                    type="text"
                                    placeholder="Name"
                                    name="name"
                                    value={formData.name}
                                    onChange={handleChange}
                                />
                                <input
                                    type="email"
                                    placeholder="Email"
                                    name="email"
                                    value={formData.email}
                                    onChange={handleChange}
                                />
                                <select
                                    name="extension"
                                    value={formData.extension}
                                    onChange={handleChange}
                                >
                                    <option value="+971">UAE (+971)</option>
                                    {/* <option value="+966">KSA (+966)</option> */}
                                </select>
                                <input
                                    type="tel"
                                    placeholder="Mobile Number"
                                    name="phone"
                                    value={formData.phone}
                                    onChange={handleChange}
                                />
                                <button type="submit" disabled={isSubmitted}>Submit</button>
                            </form>
                            {/* Error Message */}
                            {formError && <p style={{ color: 'red' }}>{formError}</p>}
                            {/* Submission Status Dialog */}
                            {submissionStatus.open && (
                                <div
                                    style={{
                                        position: 'fixed',
                                        top: '50%',
                                        left: '50%',
                                        transform: 'translate(-50%, -50%)',
                                        backgroundColor: '#383938',
                                        padding: '20px',
                                        boxShadow: '0px 0px 10px rgba(0,0,0,0.2)',
                                        textAlign: 'center',
                                    }}
                                >
                                    <p>{submissionStatus.message}</p>
                                    <button onClick={() => navigate('/ThankYouPage')}> {/* Navigate to new page */}
                                        Close
                                    </button>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
export default HeroSection;