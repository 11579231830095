import React, { useState } from 'react';
import '../styles/LandingPage.css'; // Assuming you put the styles in Banner.css
import Footer from '../components/Footer';
import CarGalleryM from '../components/CarGalleryM';
import axios from 'axios';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
function LandingPage() {
  const navigate = useNavigate(); // Initialize the navigate function
  const initialFormState = {
    name: '',
    email: '',
    phone: '',
    extension: '+971',
  };
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    extension: '+971',
  });
  const [submissionStatus, setSubmissionStatus] = useState({
    open: false,
    message: '',
    success: false,
  });
  const [isSubmitted, setIsSubmitted] = useState(false); // Track submission status
  const [formError, setFormError] = useState(''); // Track form errors

  // Handle form input changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  // Function to handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (isSubmitted) return; // Prevent resubmission if already submitted
    // Validate mandatory fields
    if (!formData.name || !formData.email || !formData.phone) {
      setFormError('Please fill in all mandatory fields.');
      return;
    }

    // Refresh access token and make Zoho API call
    try {
      await sendLeadDataToZoho();
      await sendWebhook();
      setIsSubmitted(true); // Set submitted state to true
      setFormData(initialFormState); // Clear the form
      setFormError(''); // Clear error message on successful submission
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const sendLeadDataToZoho = async () => {
    // const fullPhone = `${formData.extension} ${formData.phone}`;
    const leadData = {
      company: 'Your Company',
      first_name: formData.name,
      last_name: 'Unknown',
      email: formData.email,
      phone: formData.phone,
      brand: 'Your Brand',
      website: window.location.href,
      extension: formData.extension,
      Website :"https://falcons-luxury.com/page/mc_c200"
    };

    try {
      const response = await fetch('https://falconsgt.com/zoho_api.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Accept': '*/*',
        },
        body: JSON.stringify(leadData),
      });

      const data = await response.json();
      console.log("Full Response:", data);

      if (!response.ok) {
        console.error("Server Error Details:", data);
        throw new Error(`Error ${response.status}: ${data.message || 'Unknown error'}`);
      }

      if (data.data) {
        setSubmissionStatus({
          open: true,
          message: 'Form submitted successfully!',
          success: true,
        });
      } else {
        console.error("Error Details:", data);
        setSubmissionStatus({
          open: true,
          message: 'Error submitting form.',
          success: false,
        });
      }
    } catch (error) {
      console.error("Request failed:", error);
      setSubmissionStatus({
        open: true,
        message: 'Network error submitting form.',
        success: false,
      });
    }
  };

  const sendWebhook = async () => {
    const url = "https://www.privyr.com/api/v1/incoming-leads/0vZfjMQw/trodzEV5?name=test#generic-webhook";
    const currentPageUrl = "https://falcons-luxury.com/page/mc_c200";

    const data = {
      name: formData.name,
      email: formData.email,
      phone: formData.phone,
      display_name: formData.name,
      other_fields: {
        "Company Name": 'Your Company',
        "Page": currentPageUrl,
      }
    };

    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data),
      });

      if (!response.ok) {
        const errorText = await response.text();
        console.error("Webhook error:", errorText);
        return;
      }

      const responseData = await response.json();
      console.log("Webhook sent successfully:", responseData);

    } catch (error) {
      console.error("Network error while sending webhook:", error);
    }
  };

  return (
    <div className='landing-page'>


      <div className="banner-section-loading">
        <div className="banner-content-loading">
          <div className="banner-text-loading">
            <h2>UNLEASH THE THRILL OF LUXURY!</h2>
            <p>Get behind the wheel of the Mercedes-Benz C200 and experience sophistication like never before.</p>
          </div>
          <div className="banner-form-loading">
            <form onSubmit={handleSubmit}>
              <input
                type="text"
                placeholder="Name"
                name="name"
                value={formData.name}
                onChange={handleChange}
              />
              <input
                type="email"
                placeholder="Email"
                name="email"
                value={formData.email}
                onChange={handleChange}
              />
              <select
                name="extension"
                value={formData.extension}
                onChange={handleChange}
              >
                <option value="+971">UAE (+971)</option>
                <option value="+966">KSA (+966)</option>
              </select>
              <input
                type="tel"
                placeholder="Mobile Number"
                name="phone"
                value={formData.phone}
                onChange={handleChange}
              />
              <button type="submit" disabled={isSubmitted}>Submit</button>
            </form>
            {/* Error Message */}
            {formError && <p style={{ color: 'red' }}>{formError}</p>}
            {/* Submission Status Dialog */}
            {submissionStatus.open && (
              <div
                style={{
                  position: 'fixed',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                  backgroundColor: '#383938',
                  padding: '20px',
                  boxShadow: '0px 0px 10px rgba(0,0,0,0.2)',
                  textAlign: 'center',
                }}
              >
                <p>{submissionStatus.message}</p>
                <button onClick={() => navigate('/ThankYouPage')}> {/* Navigate to new page */}
                  Close
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
      <CarGalleryM />
      <Footer />
    </div>
  );
}

export default LandingPage;
