import React, { useState } from "react";
import "../styles/ImageSlider.css";
import SliderImage1 from "../assets/images/MAIN_COMBINED_GS8/GS803 1.jpg";
import SliderImage2 from "../assets/images/MAIN_COMBINED_GS8/GS803 2.jpg";
import SliderImage3 from "../assets/images/MAIN_COMBINED_GS8/GS803 3.jpg";
import SliderImage4 from "../assets/images/MAIN_COMBINED_GS8/GS803 4.jpg";
import SliderImage5 from "../assets/images/MAIN_COMBINED_GS8/GS803 6.jpg";
import SliderImage6 from "../assets/images/MAIN_COMBINED_GS8/GS803 7.jpg";
import SliderImage7 from "../assets/images/MAIN_COMBINED_GS8/GS803 8.jpg";
import SliderImage8 from "../assets/images/MAIN_COMBINED_GS8/GS803 9.jpg";
import SliderImage9 from "../assets/images/MAIN_COMBINED_GS8/GS803 10.jpg";
import SliderImage10 from "../assets/images/MAIN_COMBINED_GS8/GS803 11.jpg";
import SliderImage11 from "../assets/images/MAIN_COMBINED_GS8/GS803 12.jpg";
import SliderImage12 from "../assets/images/MAIN_COMBINED_GS8/GS803 13.jpg";
import SliderImage13 from "../assets/images/MAIN_COMBINED_GS8/GS803 14.jpg";
import SliderImage14 from "../assets/images/MAIN_COMBINED_GS8/GS803 15.jpg";
import SliderImage15 from "../assets/images/MAIN_COMBINED_GS8/GS803 16.jpg";
import SliderImage16 from "../assets/images/MAIN_COMBINED_GS8/GS803 17.jpg";
import SliderImage17 from "../assets/images/MAIN_COMBINED_GS8/GS803 18.jpg";
import SliderImage18 from "../assets/images/MAIN_COMBINED_GS8/GS803 19.jpg";
import SliderImage19 from "../assets/images/MAIN_COMBINED_GS8/GS803 20.jpg";
import SliderImage20 from "../assets/images/MAIN_COMBINED_GS8/GS803 21.jpg";
import SliderImage21 from "../assets/images/MAIN_COMBINED_GS8/GS803 22.jpg";
import SliderImage22 from "../assets/images/MAIN_COMBINED_GS8/GS803 23.jpg";
import SliderImage23 from "../assets/images/MAIN_COMBINED_GS8/GS803 24.jpg";
import SliderImage24 from "../assets/images/MAIN_COMBINED_GS8/GS803 25.jpg";
import SliderImage25 from "../assets/images/MAIN_COMBINED_GS8/GS803 26.jpg";

const ImageSlider = () => {
  const images = [
    SliderImage1,
    SliderImage2,
    SliderImage3,
    SliderImage4,
    SliderImage5,
    SliderImage6,
    SliderImage7,
    SliderImage8,
    SliderImage9,
    SliderImage10,
    SliderImage11,
    SliderImage12,
    SliderImage13,
    SliderImage14,
    SliderImage15,
    SliderImage16,
    SliderImage17,
    SliderImage18,
    SliderImage19,
    SliderImage20,
    SliderImage21,
    SliderImage22,
    SliderImage23,
    SliderImage24,
    SliderImage25,
  ];

  const [currentIndex, setCurrentIndex] = useState(0);

  const nextSlide = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === images.length - 1 ? 0 : prevIndex + 1
    );
  };

  const prevSlide = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? images.length - 1 : prevIndex - 1
    );
  };

  return (
    <>
      {/* <div>
        <h1 className="Slidertitle">GAC-GS8 2025</h1>
      </div> */}
      <hr className="Line" />
      <div className="slider-container">
        {/* Main Image */}
        <div className="main-slider">
          <button className="prev-button" onClick={prevSlide}>
            &#8592;
          </button>
          <img
            src={images[currentIndex]}
            alt={`Slide ${currentIndex + 1}`}
            className="slider-image"
          />
          <button className="next-button" onClick={nextSlide}>
            &#8594;
          </button>
        </div>

        {/* Thumbnail Slider */}
        <div className="thumb-slider">
          {images.map((image, index) => (
            <img
              key={index}
              src={image}
              alt={`Thumbnail ${index + 1}`}
              className={`thumb-image ${
                currentIndex === index ? "active" : ""
              }`}
              onClick={() => setCurrentIndex(index)}
            />
          ))}
        </div>
      </div>
    </>
  );
};

export default ImageSlider;
