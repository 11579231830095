import React, { useState } from 'react';
import '../styles/LandingPageNoFormGC8.css'; // Assuming you put the styles in Banner.css
import Footer from '../components/Footer';
import CarGalleryGCM from '../components/CarGalleryGCM';
import axios from 'axios';
import CarDetials from '../components/CarDetials';
import ImageSlider from '../components/ImageSlider';
import TwoImages from '../components/TwoImages';
import HeroSection from '../components/HeroSection';
import '../styles/Container.css'
function LandingPageNoFormGC8() {
  
  return (
    <div className='main_container'>
    <HeroSection></HeroSection>
    <TwoImages></TwoImages>
    <ImageSlider></ImageSlider>
    <CarDetials></CarDetials>
    <Footer />
</div>
  );
}

export default LandingPageNoFormGC8;
